export default class SubmitOnChange {
	el: HTMLInputElement | HTMLSelectElement;

	constructor(el: HTMLInputElement | HTMLSelectElement) {
		this.el = el;

		el.addEventListener('change', () => {
			el.form?.submit();
		});
	}
}
